import React, { useState } from "react";

const CoverArt = ({ coverArtUrls, albumCoverUrl, openAppModal, size = "small", css, selectedOption }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOpenModal = () => {
    if (!openAppModal) return;
    const modalContent = (
      <>
        {coverArtUrls?.large && (
          <div className="large-album-art">
            <img
              src={coverArtUrls?.large}
              alt="Cover art"
             />
          </div>
        )}

        {albumCoverUrl && (
          <div className="large-album-art mt-3">
            <img
              src={albumCoverUrl}
              alt="Album cover"
            />
          </div>
        )}

        <div className="box mt-2">
          Images generated by DALL-E with prompt assistance from ChatGPT.
        </div>
      </>
    );
    openAppModal(modalContent);
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const selectedImage = selectedOption === "albumCover" ? albumCoverUrl : coverArtUrls?.[size];

  return (
    <div
      className="cover-art cover-art-modal-trigger"
      onClick={handleOpenModal}
    >
      <img
        src={selectedImage}
        alt={selectedOption === "coverArt" ? "Cover art" : "Album cover"}
        className={`${css || ""} ${isLoaded ? "" : "loading-shimmer"}`}
        onLoad={handleImageLoad}
        loading="lazy"
      />
    </div>
  );
};

export default CoverArt;
